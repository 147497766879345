import React from 'react';
import styled from 'styled-components';

function ChatList({ chatrooms, selectedChat, onChatSelect, loading, error }) {
    return (
        <ChatListColumn>
            <ColumnHeader>Conversations</ColumnHeader>
            <ChatListContainer>
                {loading ? (
                    <LoadingText>Loading conversations...</LoadingText>
                ) : error ? (
                    <ErrorText>{error}</ErrorText>
                ) : chatrooms.length === 0 ? (
                    <NoChatsText>No conversations found</NoChatsText>
                ) : (
                    chatrooms.map((chat) => (
                        <ChatItem 
                            key={chat.id} 
                            onClick={() => onChatSelect(chat)}
                            active={selectedChat?.id === chat.id}
                        >
                            <ChatInfo>
                                <DemoTitle>Free Demo Lesson</DemoTitle>
                                <SubjectLine>
                                    <span>Subject: </span>{chat.subject}
                                </SubjectLine>
                                <GradeLine>
                                    <span>Grade: </span>{chat.grade}
                                </GradeLine>
                                <DemoDateLine>
                                    <span>When: </span>
                                    {chat.leads?.demo_date ? (
                                        chat.leads.demo_date
                                    ) : chat.demoDate ? (
                                        chat.demoDate
                                    ) : (
                                        <NotScheduled>Not Scheduled</NotScheduled>
                                    )}
                                </DemoDateLine>
                                {chat.unread > 0 && (
                                    <UnreadBadge>{chat.unread}</UnreadBadge>
                                )}
                            </ChatInfo>
                        </ChatItem>
                    ))
                )}
            </ChatListContainer>
        </ChatListColumn>
    );
}

const ChatListColumn = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
`;

const ColumnHeader = styled.div`
    padding: 20px;
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
`;

const ChatListContainer = styled.div`
    overflow-y: auto;
    flex: 1;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }
`;

const ChatItem = styled.div`
    display: flex;
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    background: ${props => props.active ? '#f5f5f5' : 'white'};

    &:hover {
        background: #f8f9fa;
    }
`;

const ChatInfo = styled.div`
    flex: 1;
    position: relative;
`;

const DemoTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const SubjectLine = styled.div`
    margin-bottom: 3px;
    span {
        font-weight: bold;
    }
`;

const GradeLine = styled.div`
    margin-bottom: 3px;
    span {
        font-weight: bold;
    }
`;

const DemoDateLine = styled.div`
    margin-bottom: 3px;
    span {
        font-weight: bold;
    }
`;

const NotScheduled = styled.span`
    color: red;
`;

const UnreadBadge = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    background: #007bff;
    color: white;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
`;

const LoadingText = styled.div`
    padding: 20px;
    text-align: center;
    color: #666;
`;

const ErrorText = styled.div`
    padding: 20px;
    text-align: center;
    color: #dc3545;
`;

const NoChatsText = styled.div`
    padding: 20px;
    text-align: center;
    color: #666;
`;

export default ChatList; 