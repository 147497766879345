import React, { useState } from 'react';
import styled from 'styled-components';
import ScheduleDemo from './ScheduleDemo';
import { studentApi } from '../../studentApi';

function LessonStatus({ selectedChat, onScheduleSuccess }) {
    const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
    const [scheduling, setScheduling] = useState(false);
    const [error, setError] = useState(null);

    const handleScheduleClick = () => {
        setError(null);
        setIsScheduleDialogOpen(true);
    };

    const handleScheduleSubmit = async (scheduleData) => {
        try {
            setScheduling(true);
            setError(null);
            
            await studentApi.scheduleDemo(selectedChat.id, scheduleData);
            
            setIsScheduleDialogOpen(false);
            if (onScheduleSuccess) {
                onScheduleSuccess(); // Callback to refresh chat data
            }
        } catch (err) {
            setError('Failed to schedule demo lesson. Please try again.');
            console.error('Schedule demo error:', err);
        } finally {
            setScheduling(false);
        }
    };

    const isStepComplete = (stepNumber) => {
        switch (stepNumber) {
            case 1:
                return true; // Always complete
            case 2:
                return selectedChat?.leads || selectedChat?.demoDate != null;
            case 3:
                return selectedChat?.leads || selectedChat?.demoDate != null;
            case 4:
                return selectedChat?.tutor != null;
            case 5:
                return false; // Always incomplete for now
            default:
                return false;
        }
    };

    return (
        <>
            <StatusContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <StatusTitle>Lesson Status</StatusTitle>
                <ProgressContainer>
                    {[1, 2, 3, 4, 5].map((step) => (
                        <StepContainer key={step}>
                            <StepCircle completed={isStepComplete(step)}>
                                {step}
                            </StepCircle>
                            <StepContent>
                                <StepText completed={isStepComplete(step)}>
                                    {step === 1 && "Request placed"}
                                    {step === 2 && "Free lesson scheduled"}
                                    {step === 3 && "Academic advisor assigned"}
                                    {step === 4 && "Perfect tutor mapped"}
                                    {step === 5 && "Free lesson completed"}
                                </StepText>
                                {step === 2 && !isStepComplete(2) && !selectedChat?.leads && (
                                    <ButtonWrapper>
                                        <ScheduleButton onClick={handleScheduleClick}>
                                            Schedule Now
                                        </ScheduleButton>
                                    </ButtonWrapper>
                                )}
                            </StepContent>
                        </StepContainer>
                    ))}
                </ProgressContainer>
            </StatusContainer>
            
            <ScheduleDemo
                isOpen={isScheduleDialogOpen}
                onClose={() => setIsScheduleDialogOpen(false)}
                onSchedule={handleScheduleSubmit}
                timezone={selectedChat?.timezone}
                isLoading={scheduling}
            />
        </>
    );
}

const StatusContainer = styled.div`
    padding: 20px;
    padding-top: 0;
`;

const StatusTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
`;

const ProgressContainer = styled.div`
    position: relative;
    padding-left: 15px;
    
    &::before {
        content: '';
        position: absolute;
        left: 25px;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: #e0e0e0;
    }
`;

const StepContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    position: relative;
`;

const StepCircle = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${props => props.completed ? '#4CAF50' : '#e0e0e0'};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    z-index: 1;
`;

const StepContent = styled.div`
    flex: 1;
`;

const StepText = styled.div`
    color: ${props => props.completed ? '#4CAF50' : '#666'};
    font-weight: ${props => props.completed ? 'bold' : 'normal'};
    margin-top: 5px;
`;

const ScheduleButton = styled.button`
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }

    &:active {
        background-color: #004085;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 10px;
`;

const ErrorMessage = styled.div`
    color: #dc3545;
    background-color: #f8d7da;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 0.9rem;
`;

export default LessonStatus; 