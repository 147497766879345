import axios from 'axios';
import { TUTOR_TOKEN, TUTOR_BASIC_INFO } from '../../constants';

// Remove the moment import

const API_URL = process.env.REACT_APP_API_URL;

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_URL,
});

// Create a separate instance for public endpoints
const publicApi = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem(TUTOR_TOKEN);
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const tutorApi = {
  register: async (userData) => {
    try {
      const response = await publicApi.post('/api/register/', userData);
      return response.data.data;
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      throw error;
    }
  },

  login: async (credentials) => {
    try {
      const response = await publicApi.post('/api/login/', credentials);
      return response.data.data;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      throw error;
    }
  },

  getTutorBasicInfo: async () => {
    try {
      const token = localStorage.getItem(TUTOR_TOKEN);
      if (!token) {
        throw new Error('No token found');
      }

      const response = await api.get('/api/tutor/basic-info/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      localStorage.setItem(TUTOR_BASIC_INFO, JSON.stringify(response.data.data));

      return response.data;
    } catch (error) {
      console.error('Error fetching tutor basic info:', error);
      throw error;
    }
  },

  updateTutorProfile: async (profileData) => {
    console.log(profileData);
    try {
      // Format the date_of_birth if it exists
      if (profileData.date_of_birth) {
        profileData.date_of_birth = new Intl.DateTimeFormat('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(profileData.date_of_birth));
      }

      //console.log(profileData);

      const response = await api.post('/api/tutor/basic-info/', profileData);
      return response.data;
    } catch (error) {
      console.error('Profile update error:', error.response?.data || error.message);
      throw error;
    }
  },

  getS3PresignedUrl: async (fileName, fileType) => {
    try {
      const response = await api.get('/api/tutor/get-presigned-url/', {
        params: { 'file_name': fileName, 'file_type': fileType }
      });
      return response.data.data.presigned_url;
    } catch (error) {
      console.error('S3 presigned URL error:', error.response?.data || error.message);
      throw error;
    }
  },

  uploadImageToS3: async (presignedUrl, file) => {
    try {
      await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        // Add these options to handle CORS
        withCredentials: false,
        crossDomain: true,
      });
    } catch (error) {
      console.error('S3 upload error:', error.response?.data || error.message);
      throw new Error('Failed to upload image to S3');
    }
  },

  async getS3PresignedUrlForViewing(objectKey) {
    try {
      const response = await api.get('/api/tutor/get-presigned-url-to-view/', {
        params: { 'object_key': objectKey }
      });
      return response.data.data.presigned_url;
    } catch (error) {
      console.error('S3 presigned URL for viewing error:', error.response?.data || error.message);
      throw error;
    }
  },

  getTutorSubjects: async () => {
    try {
      const response = await api.get('/api/tutor/subjects/');
      return response.data;
    } catch (error) {
      console.error('Error fetching tutor subjects:', error);
      throw error;
    }
  },

  updateTutorSubjects: async (subjectIds) => {
    try {
      const response = await api.put('/api/tutor/subjects/', {
        subject_ids: subjectIds
      });
      return response.data;
    } catch (error) {
      console.error('Error updating tutor subjects:', error);
      throw error;
    }
  },

  getTutorEducation: async () => {
    try {
      const response = await api.get('/api/tutor/degrees/');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching tutor degrees:', error);
      throw error;
    }
  },

  updateTutorEducation: async (educationData) => {
    try {
      const response = await api.put('/api/tutor/degrees/', educationData);
      return response.data;
    } catch (error) {
      console.error('Error updating tutor degrees:', error);
      throw error;
    }
  },

  createBankDetails: async (bankDetailsData) => {
    try {
      const response = await api.post('/api/tutor/bank-details/', bankDetailsData);
      return response.data;
    } catch (error) {
      console.error('Error creating bank details:', error.response?.data || error.message);
      throw error;
    }
  },

  getBankDetails: async () => {
    try {
      const response = await api.get('/api/tutor/bank-details/');
      return response.data;
    } catch (error) {
      console.error('Error fetching bank details:', error.response?.data || error.message);
      throw error;
    }
  },

  getLeads: async () => {
    try {
      const response = await api.get('/api/tutor/leads');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching leads:', error.response?.data || error.message);
      throw error;
    }
  },

  getDemos: async () => {
    try {
      const response = await api.get('/api/tutor/demos');
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching demos:', error.response?.data || error.message);
      throw error;
    }
  },

  acceptLead: async (leadId) => {
    try {
      const response = await api.post('/api/tutor/lead-assignment/update-status/', {
        lead_id: leadId,
        status: 'ACCEPTED'
      });
      return response.data;
    } catch (error) {
      console.error('Error accepting lead:', error.response?.data || error.message);
      throw error;
    }
  },

  declineLead: async (leadId) => {
    try {
      const response = await api.post('/api/tutor/lead-assignment/update-status/', {
        lead_id: leadId,
        status: 'DECLINED'
      });
      return response.data;
    } catch (error) {
      console.error('Error declining lead:', error.response?.data || error.message);
      throw error;
    }
  },

  // Add more API calls as needed
};

export const fetchOnboardingStatus = async () => {
  try {
    const response = await api.get('/api/tutor/onboarding-status');
    const status = response.data;
    const isComplete = Object.values(status).every(step => step === true);
    return {
      isComplete,
      completedSteps: status
    };
  } catch (error) {
    console.error('Error fetching onboarding status:', error);
    throw error;
  }
};

export const getActiveClasses = async () => {
  try {
    const response = await api.get('/api/tutor/lesson-packs/');
    return response.data.data.map(pack => ({
      id: pack.id,
      studentName: pack.student_name,
      subject: pack.subject,
      totalHours: pack.no_of_hours,
      remainingHours: parseFloat(pack.hours_remaining),
      meetingLink: pack.meeting_link,
      status: pack.status,
      expiryDate: pack.expiry_date
    }));
  } catch (error) {
    console.error('Error fetching active classes:', error.response?.data || error.message);
    throw error;
  }
};

export const getInvoices = async () => {
  try {
    const response = await api.get('/api/sessions/');
    
    // Check if response.data exists and has the expected structure
    const invoices = response.data?.data || response.data?.results || [];
    
    // Ensure we have an array before mapping
    if (!Array.isArray(invoices)) {
      console.error('Invalid response format for invoices:', response.data);
      return [];
    }

    return invoices.map(invoice => ({
      id: invoice.id,
      lessonPackId: invoice.lesson_pack,
      date: invoice.date,
      duration: parseFloat(invoice.duration),
      subject: invoice.subject,
      notes: invoice.notes,
      createdAt: invoice.created_at,
      updatedAt: invoice.updated_at
    }));
  } catch (error) {
    console.error('Error fetching invoices:', error.response?.data || error.message);
    throw error;
  }
};

export const createInvoice = async (invoiceData) => {
  try {
    const response = await api.post('/api/sessions/', {
      lesson_pack: parseInt(invoiceData.lesson_pack_id),
      date: invoiceData.date,
      duration: parseFloat(invoiceData.duration),
      subject: invoiceData.subject,
      notes: invoiceData.notes
    });
    return response.data;
  } catch (error) {
    console.error('Error creating invoice:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteInvoice = async (invoiceId) => {
  try {
    const response = await api.delete(`/api/sessions/${invoiceId}/`);
    return response.data;
  } catch (error) {
    console.error('Error deleting invoice:', error.response?.data || error.message);
    throw error;
  }
};

export const updateInvoice = async (invoiceId, invoiceData) => {
  try {
    const response = await api.put(`/api/sessions/${invoiceId}/`, {
      lesson_pack: parseInt(invoiceData.lesson_pack_id),
      date: invoiceData.date,
      duration: parseFloat(invoiceData.duration),
      subject: invoiceData.subject,
      notes: invoiceData.notes
    });
    return response.data;
  } catch (error) {
    console.error('Error updating invoice:', error.response?.data || error.message);
    throw error;
  }
};

export const getPaymentHistory = async () => {
  try {
    const response = await api.get('/api/tutor/payments/');
    return response.data.results.map(payment => ({
      paymentDate: new Date(payment.payment_date).toLocaleDateString(),
      amount: parseFloat(payment.amount).toFixed(2),
      method: payment.payment_method,
      reference: payment.transaction_id,
      status: 'Completed', // Since status isn't in the API, we'll assume completed payments
      currency: payment.currency
    }));
  } catch (error) {
    console.error('Error fetching payment history:', error.response?.data || error.message);
    throw error;
  }
};

export default tutorApi;
