import React, { useState, useEffect } from 'react';
import CreateInvoiceForm from './CreateInvoiceForm';
import { getActiveClasses, getInvoices, createInvoice, deleteInvoice, updateInvoice } from './tutorApi';
import InvoiceDialog from './InvoiceDialog';

const Class = () => {
  const [activeClasses, setActiveClasses] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [classes, setClasses] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [classesData, invoicesData] = await Promise.all([
        getActiveClasses(),
        getInvoices()
      ]);
      setActiveClasses(classesData);
      setInvoices(invoicesData);
    } catch (err) {
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const activeClasses = await getActiveClasses();
        setClasses(activeClasses);
      } catch (error) {
        console.error('Error fetching classes:', error);
        // Handle error appropriately
      }
    };

    fetchClasses();
  }, []);

  const handleEditInvoice = (invoice) => {
    setEditingInvoice({
      id: invoice.id,
      lessonPackId: invoice.lessonPackId.toString(),
      date: invoice.date,
      duration: invoice.duration.toString(),
      notes: invoice.notes
    });
    setIsDialogOpen(true);
  };

  const handleCreateInvoice = async (formData) => {
    try {
      setLoading(true);
      if (editingInvoice) {
        await updateInvoice(editingInvoice.id, formData);
      } else {
        await createInvoice(formData);
      }
      await fetchData();
      setIsDialogOpen(false);
      setEditingInvoice(null);
    } catch (err) {
      console.error('Failed to save invoice:', err);
      setError('Failed to save invoice');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteInvoice = async (invoiceId) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      try {
        setLoading(true);
        await deleteInvoice(invoiceId);
        await fetchData();
      } catch (err) {
        console.error('Failed to delete invoice:', err);
        setError('Failed to delete invoice');
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <div className="container mx-auto p-4 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Class Management</h1>

      {/* Active Classes Table */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Active Classes</h2>
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b">Student Name</th>
              <th className="py-2 px-4 border-b">Subject</th>
              <th className="py-2 px-4 border-b">Hours Completed</th>
              <th className="py-2 px-4 border-b">Total Hours</th>
              <th className="py-2 px-4 border-b">Meeting Link</th>
            </tr>
          </thead>
          <tbody>
            {classes.map((classItem) => (
              <tr key={classItem.id}>
                <td className="py-2 px-4">{classItem.studentName}</td>
                <td className="py-2 px-4">{classItem.subject}</td>
                <td className="py-2 px-4">{classItem.totalHours - classItem.remainingHours}</td>
                <td className="py-2 px-4">{classItem.totalHours}</td>
                <td className="py-2 px-4">
                  <a 
                    href={classItem.meetingLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Join Class
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Create Invoice Button */}
      <div className="mb-8">
        <button
          onClick={() => setIsDialogOpen(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Create Invoice
        </button>
      </div>

      {/* Invoice Dialog */}
      <InvoiceDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setEditingInvoice(null);
        }}
        students={activeClasses}
        onSubmit={handleCreateInvoice}
        editData={editingInvoice}
      />

      {/* Invoices Table */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Invoices</h2>
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Subject</th>
              <th className="py-2 px-4 border-b">Duration (Hours)</th>
              <th className="py-2 px-4 border-b">Notes</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{new Date(invoice.date).toLocaleDateString()}</td>
                <td className="py-2 px-4">{invoice.subject}</td>
                <td className="py-2 px-4">{invoice.duration}</td>
                <td className="py-2 px-4">{invoice.notes}</td>
                <td className="py-2 px-4">
                  <button 
                    className="text-yellow-500 hover:underline mr-2"
                    onClick={() => handleEditInvoice(invoice)}
                  >
                    Edit
                  </button>
                  <button 
                    className="text-red-500 hover:underline"
                    onClick={() => handleDeleteInvoice(invoice.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Class;
