import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { studentApi } from './studentApi';

function Profile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await studentApi.getStudentInfo();
                setProfile(data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    if (loading) {
        return <LoadingDiv>Loading...</LoadingDiv>;
    }

    return (
        <Container>
            <ProfileCard>
                <Heading>Profile Information</Heading>
                <ProfileGrid>
                    <ProfileItem>
                        <Label>First Name</Label>
                        <Value>{profile?.first_name || 'Not provided'}</Value>
                    </ProfileItem>
                    <ProfileItem>
                        <Label>Last Name</Label>
                        <Value>{profile?.last_name || 'Not provided'}</Value>
                    </ProfileItem>
                    <ProfileItem>
                        <Label>Email</Label>
                        <Value>{profile?.email || 'Not provided'}</Value>
                    </ProfileItem>
                    <ProfileItem>
                        <Label>Phone</Label>
                        <Value>{profile?.phone_no || 'Not provided'}</Value>
                    </ProfileItem>
                    <ProfileItem>
                        <Label>Timezone</Label>
                        <Value>{profile?.timezone || 'Not provided'}</Value>
                    </ProfileItem>
                </ProfileGrid>
            </ProfileCard>
        </Container>
    );
}

const Container = styled.div`
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
`;

const ProfileCard = styled.div`
    background: white;
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 1.5rem;
    }
`;

const Heading = styled.h2`
    margin-bottom: 2rem;
    color: #2d3748;
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: -0.5px;
`;

const ProfileGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;

    @media (max-width: 768px) {
        gap: 1.5rem;
    }
`;

const ProfileItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background: #f7fafc;
    border-radius: 12px;
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }
`;

const Label = styled.label`
    font-size: 0.9rem;
    color: #4a5568;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
`;

const Value = styled.span`
    font-size: 1.1rem;
    color: #2d3748;
    font-weight: 500;
`;

export default Profile;