import axios from 'axios';
import { STUDENT_TOKEN } from '../../constants';

const API_URL = process.env.REACT_APP_API_URL;

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_URL,
});

// Create a separate instance for public endpoints
const publicApi = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem(STUDENT_TOKEN);
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const studentApi = {
  login: async (credentials) => {
    try {
      const response = await publicApi.post('/api/login/', credentials);
      //console.log(response.data);
      if (!response.data.data.user || !response.data.data.token) {
        throw new Error('Invalid response format from server');
      }
      return response.data.data;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      throw error;
    }
  },

  register: async (userData) => {
    try {
      const response = await publicApi.post('/api/register/', userData);
      //console.log(response.data);
      if (!response.data.data.user || !response.data.data.token) {
        throw new Error('Invalid response format from server');
      }
      return response.data.data;
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      throw error;
    }
  },

  getStudentInfo: async () => {
    try {
      const response = await api.get('/api/student/profile/');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching student info:', error.response?.data || error.message);
      throw error;
    }
  },

  updateProfile: async (profileData) => {
    try {
      const response = await api.put('/api/student/profile/', profileData);
      return response.data;
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error.message);
      throw error;
    }
  },

  getChatrooms: async () => {
    try {
      const response = await api.get('/api/chats/');
      return response.data;
    } catch (error) {
      console.error('Error fetching chatrooms:', error.response?.data || error.message);
      throw error;
    }
  },

  getChatroomMessages: async (chatroomId) => {
    try {
      const response = await api.get(`/api/chats/${chatroomId}/messages/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching messages:', error.response?.data || error.message);
      throw error;
    }
  },

  sendMessage: async (chatroomId, content) => {
    try {
      const response = await api.post(`/api/chats/${chatroomId}/send_message/`, {
        content: content
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message:', error.response?.data || error.message);
      throw error;
    }
  },

  markMessagesAsRead: async (chatroomId) => {
    try {
      const response = await api.post(`/api/chats/${chatroomId}/mark_as_read/`);
      return response.data;
    } catch (error) {
      console.error('Error marking messages as read:', error.response?.data || error.message);
      throw error;
    }
  },

  submitEnquiry: async (formData, token = null) => {
    console.log(token);
    try {
      // Use the authenticated api instance if token exists, otherwise use publicApi
      const apiInstance = token ? api : publicApi;
      
      const response = await apiInstance.post('/api/student/enquiry/', formData);
      return response.data.data;
    } catch (error) {
      console.error('Enquiry submission error:', error.response?.data || error.message);
      throw error;
    }
  },

  scheduleDemo: async (chatroomId, scheduleData) => {
    try {
      const response = await api.post(`/api/schedule-demo/`, {
        date: scheduleData.date,
        time: scheduleData.time,
        timezone: scheduleData.timezone,
        chatroom_id: chatroomId
      });
      return response.data;
    } catch (error) {
      console.error('Error scheduling demo:', error.response?.data || error.message);
      throw error;
    }
  },
};

export const getActiveClasses = async () => {
  try {
    const response = await api.get('/api/student/lesson-packs/');
    
    // Check if response.data exists and has data property
    if (!response.data || !response.data.data) {
      console.warn('Unexpected response format:', response);
      return [];
    }

    // Ensure data is an array
    const lessonPacks = Array.isArray(response.data.data) 
      ? response.data.data 
      : [];

    return lessonPacks.map(pack => ({
      id: pack.id,
      studentName: pack.tutor_name,
      subject: pack.subject,
      totalHours: pack.no_of_hours,
      remainingHours: parseFloat(pack.hours_remaining || 0),
      meetingLink: pack.meeting_link,
      status: pack.status,
      expiryDate: pack.expiry_date
    }));
  } catch (error) {
    console.error('Error fetching active classes:', error.response?.data || error.message);
    return []; // Return empty array instead of throwing error
  }
};

export const getInvoices = async () => {
  try {
    const response = await api.get('/api/sessions/');
    
    if (!response.data.data) {
      console.error('No data in response:', response);
      return [];
    }

    let invoices = response.data.data || [];

    if (typeof invoices === 'object' && Object.keys(invoices).length === 0) {
      invoices = [];
    }
    
    return invoices.map(invoice => ({
      id: invoice.id || '',
      lessonPackId: invoice.lesson_pack || '',
      date: invoice.date || '',
      duration: parseFloat(invoice.duration || 0),
      subject: invoice.subject || '',
      notes: invoice.notes || '',
      createdAt: invoice.created_at || '',
      updatedAt: invoice.updated_at || ''
    }));
  } catch (error) {
    console.error('Error fetching invoices:', error.response?.data || error.message);
    throw error;
  }
};

export const getStudentProfile = async () => {
  try {
    const response = await axios.get('/api/student/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching student profile:', error);
    throw error;
  }
};

export default studentApi;
