import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { studentApi } from './studentApi';

function Dashboard() {
    const [profile, setProfile] = useState(null);
    const [chatrooms, setChatrooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const [profileData, chatroomsData] = await Promise.all([
                    studentApi.getStudentInfo(),
                    studentApi.getChatrooms()
                ]);
                setProfile(profileData);
                const formattedChats = chatroomsData.results.map(chat => ({
                    id: chat.id,
                    title: chat.other_user_name,
                    lastMessage: chat.last_message ? chat.last_message.content : 'No messages yet',
                    unread: chat.unread_count,
                    subject: chat.prospect_details?.subject,
                    dateTime: new Date(chat.created_at).toLocaleDateString()
                }));
                setChatrooms(formattedChats);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    if (loading) {
        return <LoadingDiv>Loading...</LoadingDiv>;
    }

    return (
        <Container>
            <WelcomeSection>
                <WelcomeTitle>
                    Welcome back, <StudentName>{profile?.first_name || 'Student'}</StudentName>!
                </WelcomeTitle>
                <WelcomeSubtitle>Ready to continue your learning journey?</WelcomeSubtitle>
            </WelcomeSection>

            <ChatroomsSection>
                <SectionHeader>
                    <SectionTitle>Active Conversations</SectionTitle>
                    <ViewAllLink to="/student/inbox">View All</ViewAllLink>
                </SectionHeader>
                <ChatroomsList>
                    {chatrooms.length === 0 ? (
                        <NoChats>No active conversations yet</NoChats>
                    ) : (
                        chatrooms.map((chat) => (
                            <ChatItem key={chat.id}>
                                <ChatItemMain>
                                    <ChatItemHeader>
                                        <ChatTitle>{chat.title}</ChatTitle>
                                        {chat.unread > 0 && (
                                            <UnreadBadge>{chat.unread}</UnreadBadge>
                                        )}
                                    </ChatItemHeader>
                                    <ChatSubject>{chat.subject || 'General Discussion'}</ChatSubject>
                                    <ChatLastMessage>{chat.lastMessage}</ChatLastMessage>
                                </ChatItemMain>
                                <ChatItemFooter>
                                    <ChatDate>{chat.dateTime}</ChatDate>
                                    <ChatLink to={`/student/inbox`}>View Chat →</ChatLink>
                                </ChatItemFooter>
                            </ChatItem>
                        ))
                    )}
                </ChatroomsList>
            </ChatroomsSection>

            <NewLessonSection>
                <FreeClassButton onClick={() => navigate('/get-free-class')}>
                    Get Your Free Class
                </FreeClassButton>
            </NewLessonSection>
        </Container>
    );
}

// Styled Components
const Container = styled.div`
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
`;

const WelcomeSection = styled.section`
    margin-bottom: 3rem;
    text-align: center;
`;

const WelcomeTitle = styled.h1`
    font-size: 2.8rem;
    color: #2d3748;
    margin-bottom: 0.5rem;
    font-weight: 600;
    letter-spacing: -0.5px;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const StudentName = styled.span`
    color: #4299e1;
    font-weight: 700;
`;

const WelcomeSubtitle = styled.p`
    color: #4a5568;
    font-size: 1.25rem;
    font-weight: 500;
`;

const ChatroomsSection = styled.section`
    background: #fff;
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
`;

const ViewAllLink = styled(Link)`
    color: #4299e1;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;

    &:hover {
        color: #2b6cb0;
    }
`;

const ChatroomsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ChatItem = styled.div`
    background: #f7fafc;
    border-radius: 12px;
    padding: 1.5rem;
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }
`;

const ChatItemMain = styled.div`
    margin-bottom: 1rem;
`;

const ChatItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const ChatTitle = styled.h3`
    font-size: 1.1rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
`;

const UnreadBadge = styled.span`
    background: #4299e1;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    font-weight: 500;
`;

const ChatSubject = styled.p`
    color: #4a5568;
    font-size: 0.9rem;
    margin: 0.25rem 0;
    font-weight: 500;
`;

const ChatLastMessage = styled.p`
    color: #718096;
    font-size: 0.95rem;
    margin: 0.5rem 0;
    line-height: 1.5;
`;

const ChatItemFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
`;

const ChatDate = styled.span`
    color: #718096;
    font-size: 0.875rem;
`;

const ChatLink = styled(Link)`
    color: #4299e1;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.95rem;
    transition: color 0.2s;

    &:hover {
        color: #2b6cb0;
    }
`;

const NewLessonSection = styled.section`
    text-align: center;
    margin-top: 3rem;
`;

const FreeClassButton = styled.button`
    background: #4299e1;
    color: white;
    border: none;
    padding: 1rem 2.5rem;
    border-radius: 12px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    font-family: 'Poppins', sans-serif;

    &:hover {
        background: #2b6cb0;
        transform: translateY(-2px);
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 1rem;
    }
`;

const NoChats = styled.div`
    text-align: center;
    padding: 2rem;
    color: #718096;
    font-size: 1.1rem;
    font-weight: 500;
`;

export default Dashboard;
