import React from 'react';
import styled from 'styled-components';

function RequirementDetails({ selectedChat }) {
    return (
        <>
            <SectionTitle>Requirement Details</SectionTitle>
            <DetailSection>
                <DetailRow>
                    <Label>Subject:</Label>
                    <Value>{selectedChat.subject || 'N/A'}</Value>
                </DetailRow>
                <DetailRow>
                    <Label>Grade:</Label>
                    <Value>{selectedChat.grade || 'N/A'}</Value>
                </DetailRow>
                <DetailRow>
                    <Label>Notes:</Label>
                    <Value>{selectedChat.notes || 'N/A'}</Value>
                </DetailRow>
            </DetailSection>
        </>
    );
}

const SectionTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
`;

const DetailSection = styled.div`
    padding: 10px 0;
`;

const DetailRow = styled.div`
    display: flex;
    margin: 10px 0;
    align-items: flex-start;
`;

const Label = styled.span`
    font-weight: bold;
    min-width: 80px;
    margin-right: 10px;
`;

const Value = styled.span`
    flex: 1;
    color: #444;
`;

export default RequirementDetails; 