import React from 'react';
import styled from 'styled-components';

function MeetingDetails({ selectedChat }) {
    //console.log(selectedChat)
    // Get demo details only from leads object
    const demoDate = selectedChat?.leads?.demo_date;
    const demoTime = selectedChat?.leads?.demo_time;
    const timezone = selectedChat?.leads?.timezone;

    return (
        <>
            <SectionTitle>Meeting Details</SectionTitle>
            <DetailSection>
                <DetailRow>
                    <Label>Date:</Label>
                    <Value>
                        {demoDate 
                            ? demoDate
                            : 'N/A'}
                    </Value>
                </DetailRow>
                <DetailRow>
                    <Label>Time:</Label>
                    <Value>{demoTime || 'N/A'}</Value>
                </DetailRow>
                <DetailRow>
                    <Label>Timezone:</Label>
                    <Value>{timezone || 'N/A'}</Value>
                </DetailRow>
            </DetailSection>
        </>
    );
}

const SectionTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
`;

const DetailSection = styled.div`
    padding: 10px 0;
`;

const DetailRow = styled.div`
    display: flex;
    margin: 10px 0;
    align-items: flex-start;
`;

const Label = styled.span`
    font-weight: bold;
    min-width: 80px;
    margin-right: 10px;
`;

const Value = styled.span`
    flex: 1;
    color: #444;
`;

export default MeetingDetails; 