import React from 'react';
import Sidebar from './Sidebar';
import Inbox from './inbox/Inbox';


function StudentHome({ activeComponent }) {

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Inbox':
        return <Inbox />;
      default:
        return <Inbox/>;
    }
  };

  return (
    <Sidebar activeComponent={activeComponent}>
      {renderComponent()}
    </Sidebar>
  );
}

export default StudentHome;