import { Dialog, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const userTypes = [
  { id: 'student', name: 'Student', description: 'I want to learn' },
  { id: 'tutor', name: 'Tutor', description: 'I want to teach' }
]

export default function UserTypeDialog({ isOpen, onClose, actionType, onSelectUserType }) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/40 backdrop-blur-sm" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="mx-auto max-w-sm rounded-2xl bg-white p-8 shadow-2xl w-full transform transition-all">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">
              {actionType === 'login' ? 'Welcome Back!' : 'Join Us'}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          
          <p className="text-gray-600 mb-6">
            {actionType === 'login' 
              ? 'Please select how you want to login' 
              : 'Choose how you want to join our platform'}
          </p>
          
          <div className="space-y-4">
            {userTypes.map((type) => (
              <button
                key={type.id}
                onClick={() => onSelectUserType(type.id)}
                className="w-full flex items-center justify-between rounded-xl p-4 text-left hover:bg-gray-50 border border-gray-200 hover:border-indigo-500 transition-all duration-200 group"
              >
                <div className="flex items-center space-x-4">
                  <div className={`p-3 rounded-lg ${
                    type.id === 'student' 
                      ? 'bg-blue-50 text-blue-600 group-hover:bg-blue-100' 
                      : 'bg-purple-50 text-purple-600 group-hover:bg-purple-100'
                  } transition-colors`}>
                    {type.id === 'student' ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                      </svg>
                    )}
                  </div>
                  <div>
                    <p className="font-semibold text-gray-900 text-lg">{type.name}</p>
                    <p className="text-gray-500 text-sm">{type.description}</p>
                  </div>
                </div>
                <ChevronDownIcon className="h-5 w-5 rotate-[-90deg] text-gray-400 group-hover:text-indigo-500 transition-colors" />
              </button>
            ))}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
} 