import React, { useState } from 'react';
import { studentApi } from '../../dashboard/student/studentApi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import { STUDENT_TOKEN } from '../../constants';

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    lessonFor: '',
    level: '',
    grade: '',
    subject: '',
    note: '',
    countryCode: '',
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  const { isAuthenticated, user } = useAuth();
  const totalSteps = isAuthenticated ? 6 : 7;

  const mockData = {
    levels: ['School', 'College', 'Adult'],
    schoolGrades: ['Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12'],
    collegeGrades: ['Freshman', 'Sophomore', 'Junior', 'Senior'],
    adultGrades: ['Degree Program', 'Online Course', 'Bootcamp', 'Other'],
    subjects: ['Mathematics', 'Physics', 'Chemistry', 'Biology', 'French', 'Computer Programming', 'Other'],
    countryCodes: ['+1', '+44', '+91', '+61', '+86']
  };

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const isStepValid = () => {
    setError(''); // Clear any previous errors
    
    switch(currentStep) {
      case 1:
        if (!formData.lessonFor) {
          setError('Please select who this lesson is for');
          return false;
        }
        return true;

      case 2:
        if (!formData.level) {
          setError('Please select a level');
          return false;
        }
        return true;

      case 3:
        if (!formData.grade) {
          setError('Please select a grade');
          return false;
        }
        return true;

      case 4:
        if (!formData.subject) {
          setError('Please select a subject');
          return false;
        }
        return true;

      case 5:
        if (!formData.note || formData.note.trim().length < 10) {
          setError('Please provide more details about your learning goals (minimum 10 characters)');
          return false;
        }
        return true;

      case 6:
        if (!formData.countryCode) {
          setError('Please select a country code');
          return false;
        }
        if (!formData.phone || formData.phone.replace(/\D/g, '').length < 10) {
          setError('Please enter a valid 10-digit phone number');
          return false;
        }
        if (isAuthenticated) {
          if (!formData.note || formData.note.trim().length < 10) {
            setError('Please provide more details about your learning goals');
            return false;
          }
        }
        return true;

      case 7:
        if (!isAuthenticated) {
          if (!formData.firstName || formData.firstName.trim().length < 2) {
            setError('Please enter your first name');
            return false;
          }
          if (!formData.lastName || formData.lastName.trim().length < 2) {
            setError('Please enter your last name');
            return false;
          }
          if (!formData.email || !formData.email.includes('@') || !formData.email.includes('.')) {
            setError('Please enter a valid email address');
            return false;
          }
          if (!formData.password || formData.password.length < 8) {
            setError('Password must be at least 8 characters long');
            return false;
          }
        }
        return true;

      default:
        return true;
    }
  };

  const formatPhoneNumber = (countryCode, phone) => {
    // Remove all non-digit characters
    const cleaned = phone.replace(/\D/g, '');
    
    // Format based on length and country code
    switch(countryCode) {
      case '+1': // US/Canada
        if (cleaned.length > 0) {
          let formatted = '(' + cleaned.slice(0, 3);
          if (cleaned.length > 3) {
            formatted += ') ' + cleaned.slice(3, 6);
            if (cleaned.length > 6) {
              formatted += '-' + cleaned.slice(6, 10);
            }
          }
          return formatted;
        }
        break;
        
      case '+44': // UK
        if (cleaned.length > 0) {
          let formatted = cleaned.slice(0, 4);
          if (cleaned.length > 4) {
            formatted += ' ' + cleaned.slice(4, 7);
            if (cleaned.length > 7) {
              formatted += ' ' + cleaned.slice(7, 10);
            }
          }
          return formatted;
        }
        break;
        
      case '+91': // India
        if (cleaned.length > 0) {
          let formatted = cleaned.slice(0, 5);
          if (cleaned.length > 5) {
            formatted += ' ' + cleaned.slice(5, 10);
          }
          return formatted;
        }
        break;
        
      default: // Generic format
        if (cleaned.length > 0) {
          let formatted = cleaned.slice(0, 3);
          if (cleaned.length > 3) {
            formatted += ' ' + cleaned.slice(3, 6);
            if (cleaned.length > 6) {
              formatted += ' ' + cleaned.slice(6, 10);
            }
          }
          return formatted;
        }
    }
    return cleaned;
  };

  const handleInputChange = (field, value) => {
    if (field === 'phone') {
      // Limit to 10 digits and format
      const cleaned = value.replace(/\D/g, '').slice(0, 10);
      const formatted = formatPhoneNumber(formData.countryCode, cleaned);
      setFormData({ ...formData, phone: formatted });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  const testimonials = [
    {
      text: "My daughter's grades improved significantly after just a few sessions. The personalized attention made all the difference!",
      author: "Sarah Johnson",
      role: "Parent",
      rating: 5
    },
    {
      text: "The tutors here are not just knowledgeable but also very patient. They make complex topics easy to understand.",
      author: "Michael Chen",
      role: "College Student",
      rating: 5
    },
    {
      text: "Best decision I made for my son's education. The structured approach and regular feedback helped him excel in math.",
      author: "David Wilson",
      role: "Parent",
      rating: 5
    },
    {
      text: "I was struggling with Physics, but after these sessions, I've gained so much confidence. Highly recommended!",
      author: "Emma Thompson",
      role: "High School Student",
      rating: 5
    },
    {
      text: "The flexibility and quality of teaching is outstanding. My children love their tutoring sessions!",
      author: "Lisa Anderson",
      role: "Parent",
      rating: 5
    },
    {
      text: "Professional, punctual, and very effective teaching methods. Worth every penny!",
      author: "Robert Martinez",
      role: "Adult Learner",
      rating: 5
    },
    {
      text: "The personalized learning plan really helped me achieve my academic goals. Thank you!",
      author: "Jessica Lee",
      role: "University Student",
      rating: 5
    }
  ];

  const nextStep = () => {
    if (isStepValid()) {
      if (currentStep === totalSteps - 1) {
        // If we're on the second-to-last step, move to last step
        setCurrentStep(totalSteps);
      } else {
        setCurrentStep(prev => Math.min(prev + 1, totalSteps));
      }
    }
  };

  const prevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const StarRating = () => (
    <div className="flex gap-1 mb-4">
      {[...Array(5)].map((_, index) => (
        <svg
          key={index}
          className="w-6 h-6 text-yellow-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      ))}
    </div>
  );

  const renderProgressBar = () => {
    return (
      <div className="mb-12">
        <div className="h-3 bg-gray-200 rounded-full">
          <div
            className="h-full bg-blue-600 rounded-full transition-all duration-300"
            style={{ width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%` }}
          ></div>
        </div>
      </div>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-8">Who is this lesson for?</h2>
            {['Me', 'My Child', 'Someone Else'].map(option => (
              <button
                key={option}
                type="button"
                onClick={() => handleInputChange('lessonFor', option)}
                className={`w-full p-6 text-left rounded-xl border-2 text-xl transition-all duration-200 ${
                  formData.lessonFor === option 
                    ? 'border-blue-600 bg-blue-100 text-blue-700 font-bold shadow-md transform scale-[1.02]' 
                    : 'border-gray-300 hover:border-blue-400 hover:bg-gray-50 font-semibold'
                }`}
              >
                <div className="flex items-center">
                  <div className={`w-6 h-6 rounded-full border-2 mr-4 flex items-center justify-center ${
                    formData.lessonFor === option 
                      ? 'border-blue-600 bg-blue-600' 
                      : 'border-gray-400'
                  }`}>
                    {formData.lessonFor === option && (
                      <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    )}
                  </div>
                  {option}
                </div>
              </button>
            ))}
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-8">Select Level</h2>
            {mockData.levels.map(level => (
              <button
                key={level}
                onClick={() => handleInputChange('level', level)}
                className={`w-full p-6 text-left rounded-xl border-2 text-xl font-semibold transition-all ${
                  formData.level === level 
                    ? 'border-blue-600 bg-blue-50 text-blue-600' 
                    : 'border-gray-300 hover:border-blue-400'
                }`}
              >
                {level}
              </button>
            ))}
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-8">Select Grade</h2>
            <select
              value={formData.grade}
              onChange={(e) => handleInputChange('grade', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
            >
              <option value="">Select Grade</option>
              {formData.level === 'School' 
                ? mockData.schoolGrades.map(grade => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))
                : formData.level === 'College'
                ? mockData.collegeGrades.map(grade => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))
                : mockData.adultGrades.map(grade => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))
              }
            </select>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-8">Select Subject</h2>
            <select
              value={formData.subject}
              onChange={(e) => handleInputChange('subject', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
            >
              <option value="">Select Subject</option>
              {mockData.subjects.map(subject => (
                <option key={subject} value={subject}>{subject}</option>
              ))}
            </select>
          </div>
        );

      case 5:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-4">Tell us about your learning goals</h2>
            <p className="text-gray-600 text-lg mb-6">
              Share the specific topics or skills you'd like to focus on in your free trial lesson
            </p>
            <textarea
              value={formData.note}
              onChange={(e) => handleInputChange('note', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-base font-normal focus:border-blue-600 focus:ring-2 focus:ring-blue-200 h-40"
              placeholder="Example: I need help with quadratic equations. I'm having trouble with word problems and would like to improve my problem-solving skills."
            />
          </div>
        );

      case 6:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-8">Contact Information</h2>
            <div className="space-y-2">
              <div className="flex gap-2">
                <select
                  value={formData.countryCode}
                  onChange={(e) => {
                    handleInputChange('countryCode', e.target.value);
                    // Reformat existing phone number when country code changes
                    if (formData.phone) {
                      const cleaned = formData.phone.replace(/\D/g, '');
                      const formatted = formatPhoneNumber(e.target.value, cleaned);
                      setFormData(prev => ({ ...prev, phone: formatted }));
                    }
                  }}
                  className="w-1/4 p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
                >
                  <option value="">Code</option>
                  {mockData.countryCodes.map(code => (
                    <option key={code} value={code}>{code}</option>
                  ))}
                </select>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => handleInputChange('phone', e.target.value)}
                  className="w-3/4 p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
                  placeholder="Phone Number"
                />
              </div>
              <p className="text-sm text-gray-500 ml-1">
                {formData.countryCode ? 
                  `Format: ${formData.countryCode} + 10 digit number` : 
                  'Please select country code first'
                }
              </p>
            </div>
          </div>
        );

      case 7:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-8">Personal Information</h2>
            <input
              type="text"
              value={formData.firstName}
              onChange={(e) => handleInputChange('firstName', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
              placeholder="First Name"
            />
            <input
              type="text"
              value={formData.lastName}
              onChange={(e) => handleInputChange('lastName', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
              placeholder="Last Name"
            />
            <input
              type="email"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
              placeholder="Email Address"
            />
            <input
              type="password"
              value={formData.password}
              onChange={(e) => handleInputChange('password', e.target.value)}
              className="w-full p-6 border-2 border-gray-300 rounded-xl text-xl font-semibold focus:border-blue-600 focus:ring-2 focus:ring-blue-200"
              placeholder="Create Password"
            />
            <p className="text-sm text-gray-500 ml-1">
              Password must be at least 8 characters long
            </p>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isStepValid()) {
      try {
        setIsSubmitting(true);
        setError('');
        
        // If user is authenticated, use their existing information
        const submissionData = isAuthenticated ? {
          ...formData,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        } : formData;

        // Get token from localStorage instead of user object
        const token = localStorage.getItem(STUDENT_TOKEN);
        
        // Submit the enquiry with token if it exists in localStorage
        const response = await studentApi.submitEnquiry(
          submissionData, 
          token
        );
        
        if (isAuthenticated) {
          // If already logged in, just redirect to dashboard
          navigate('/student/inbox', { replace: true });
        } else {
          // Handle new user login as before
          try {
            const { user, token } = response;
            await login(user, token, 'student');
            navigate('/student/dashboard', { replace: true });
          } catch (loginError) {
            console.error('Login error:', loginError);
            alert('Thank you for your enquiry! Please log in to continue.');
            navigate('/student/login');
          }
        }
        
      } catch (error) {
        console.error('Submission error:', error);
        setError(error.response?.data?.message || 'Failed to submit the form. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="min-h-screen flex">
      <div className="w-full md:w-2/3 bg-white p-6 md:p-12 overflow-y-auto">
        <div className="max-w-3xl mx-auto">
          {renderProgressBar()}
          <form 
            onSubmit={(e) => {
              e.preventDefault();
              if (currentStep === totalSteps) {
                handleSubmit(e);
              }
            }} 
            className="space-y-8"
          >
            {renderStep()}
            {error && (
              <div className="mt-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                {error}
              </div>
            )}
            <div className="flex justify-between mt-12">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="px-4 py-3 md:px-8 md:py-4 bg-gray-100 text-gray-800 rounded-xl text-base md:text-lg font-semibold hover:bg-gray-200 transition-colors"
                >
                  Previous
                </button>
              )}
              {(currentStep < totalSteps || (!isAuthenticated && currentStep < 7)) ? (
                <button
                  type="button"
                  onClick={nextStep}
                  className="px-4 py-3 md:px-8 md:py-4 bg-blue-600 text-white rounded-xl text-base md:text-lg font-semibold hover:bg-blue-700 transition-colors ml-auto"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-4 py-3 md:px-8 md:py-4 ${
                    isSubmitting ? 'bg-green-400' : 'bg-green-600 hover:bg-green-700'
                  } text-white rounded-xl text-base md:text-lg font-semibold transition-colors ml-auto`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="hidden md:block w-1/3 bg-gray-50 p-8">
        <div className="h-full flex items-center">
          <div className="bg-white rounded-2xl p-8 shadow-lg">
            <div className="flex flex-col h-full">
              <StarRating />
              <blockquote className="flex-grow">
                <p className="text-xl font-medium text-gray-700 mb-6">
                  "{testimonials[currentStep - 1].text}"
                </p>
              </blockquote>
              <footer className="mt-6">
                <p className="text-lg font-semibold text-gray-900">
                  {testimonials[currentStep - 1].author}
                </p>
                <p className="text-gray-600">
                  {testimonials[currentStep - 1].role}
                </p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
