import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

function ScheduleDemo({ isOpen, onClose, onSchedule, timezone, isLoading = false }) {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    
    // Get user's timezone if not provided
    const userTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Generate next 7 days
    const dateOptions = useMemo(() => {
        const options = [];
        const today = new Date();
        
        for (let i = 1; i <= 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            
            options.push({
                value: date.toISOString().split('T')[0],
                label: date.toLocaleDateString('en-US', { 
                    weekday: 'short', 
                    month: 'short', 
                    day: 'numeric' 
                })
            });
        }
        return options;
    }, []);

    // Generate time slots (24 hours)
    const timeOptions = useMemo(() => {
        const options = [];
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0');
            const time = `${hour}:00`;
            options.push({
                value: time,
                label: new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                })
            });
        }
        return options;
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onSchedule({
                date: selectedDate,
                time: selectedTime,
                timezone: userTimezone
            });
            onClose();
            // Refresh the page after successful scheduling
            window.location.reload();
        } catch (error) {
            console.error('Error scheduling demo:', error);
            // You might want to show an error message to the user here
        }
    };

    const handleDateClick = (dateValue) => {
        setSelectedDate(dateValue);
    };

    const handleTimeClick = (timeValue) => {
        setSelectedTime(timeValue);
    };

    if (!isOpen) return null;

    const isScheduleEnabled = selectedDate && selectedTime;

    return (
        <DialogOverlay>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Schedule Your Demo Lesson</DialogTitle>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </DialogHeader>

                <Form onSubmit={handleSubmit}>
                    <TimezoneInfo>
                        Your timezone: <strong>{userTimezone}</strong>
                    </TimezoneInfo>

                    <SelectionContainer>
                        <SelectionColumn>
                            <Label>Select Date</Label>
                            <DateOptionsContainer>
                                {dateOptions.map(date => (
                                    <DateOption
                                        key={date.value}
                                        type="button"
                                        selected={selectedDate === date.value}
                                        onClick={() => handleDateClick(date.value)}
                                    >
                                        {date.label}
                                    </DateOption>
                                ))}
                            </DateOptionsContainer>
                        </SelectionColumn>

                        <SelectionColumn>
                            <Label>Select Time</Label>
                            <TimeOptionsContainer>
                                {timeOptions.map(time => (
                                    <TimeOption
                                        key={time.value}
                                        type="button"
                                        selected={selectedTime === time.value}
                                        onClick={() => handleTimeClick(time.value)}
                                    >
                                        {time.label}
                                    </TimeOption>
                                ))}
                            </TimeOptionsContainer>
                        </SelectionColumn>
                    </SelectionContainer>

                    <ButtonGroup>
                        <CancelButton 
                            type="button" 
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Cancel
                        </CancelButton>
                        <ScheduleButton 
                            type="submit"
                            disabled={!isScheduleEnabled || isLoading}
                        >
                            {isLoading 
                                ? 'Scheduling...' 
                                : (isScheduleEnabled ? 'Schedule Demo' : 'Select Date and Time')
                            }
                        </ScheduleButton>
                    </ButtonGroup>
                </Form>
            </DialogContent>
        </DialogOverlay>
    );
}

const DialogOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const DialogContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const DialogHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const DialogTitle = styled.h2`
    margin: 0;
    font-size: 1.5rem;
    color: #333;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    color: #666;
    
    &:hover {
        color: #333;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TimezoneInfo = styled.div`
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #666;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Label = styled.label`
    font-weight: 500;
    color: #333;
`;

const Input = styled.input`
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;

    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 10px;
`;

const Button = styled.button`
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
`;

const CancelButton = styled(Button)`
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    color: #333;

    &:hover {
        background-color: #e9ecef;
    }
`;

const SelectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`;

const SelectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const DateOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 300px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }
`;

const TimeOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 300px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }
`;

const OptionButton = styled.button`
    padding: 8px 12px;
    border: 1px solid ${props => props.selected ? '#007bff' : '#ddd'};
    border-radius: 4px;
    background: ${props => props.selected ? '#007bff' : 'white'};
    color: ${props => props.selected ? 'white' : '#333'};
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;

    &:hover {
        background: ${props => props.selected ? '#0056b3' : '#f8f9fa'};
        border-color: #007bff;
    }
`;

const DateOption = styled(OptionButton)`
    text-align: left;
`;

const TimeOption = styled(OptionButton)`
    text-align: center;
`;

const ScheduleButton = styled(Button)`
    background-color: ${props => props.disabled ? '#e0e0e0' : '#007bff'};
    border: none;
    color: ${props => props.disabled ? '#999' : 'white'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? 0.7 : 1};
    min-width: 150px;

    &:hover {
        background-color: ${props => props.disabled ? '#e0e0e0' : '#0056b3'};
    }
`;

export default ScheduleDemo; 