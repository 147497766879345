import axios from 'axios';
import { STAFF_TOKEN } from '../../constants';

// Remove the moment import

const API_URL = process.env.REACT_APP_API_URL;

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_URL,
});

// Create a separate instance for public endpoints
const publicApi = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem(STAFF_TOKEN);
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const staffApi = {
  login: async (credentials) => {
    try {
      const response = await publicApi.post('/api/login/', credentials);
      return response.data.data;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      throw error;
    }
  },

  getStaffBasicInfo: async () => {
    try {
      const response = await api.get('/api/staff/profile/');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching staff info:', error.response?.data || error.message);
      throw error;
    }
  },

  sendMessage: async (chatroomId, content) => {
    try {
      const response = await api.post(`/api/chats/${chatroomId}/send_message/`, {
        content: content
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message:', error.response?.data || error.message);
      throw error;
    }
  },

  markMessagesAsRead: async (chatroomId) => {
    try {
      const response = await api.post(`/api/chats/${chatroomId}/mark_as_read/`);
      return response.data;
    } catch (error) {
      console.error('Error marking messages as read:', error.response?.data || error.message);
      throw error;
    }
  },

  getChatrooms: async() => {
    try {
        const response = await api.get('/api/chats');
        return response.data;
      } catch (error) {
        console.error('Error fetching chatrooms:', error);
        throw error;
      }
  },

  getChatroomMessages: async (chatroomId) => {
    try {
      const response = await api.get(`/api/chats/${chatroomId}/messages/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching messages:', error.response?.data || error.message);
      throw error;
    }
  },

  markMessagesAsRead: async (chatroomId) => {
    try {
      const response = await api.post(`/api/chats/${chatroomId}/mark_as_read/`);
      return response.data;
    } catch (error) {
      console.error('Error marking messages as read:', error.response?.data || error.message);
      throw error;
    }
  },

};

export default staffApi;