import { useEffect } from 'react';

export default function Payments() {

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Make a Payment: <a href='https://paypal.me/mathsforce' className="text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">Paypal</a>
          </h3>
          <div className="mt-5">
            <div id="paypal-container-PKJA3K4NLHB5S"></div>
          </div>
        </div>
      </div>
    </div>
  );
} 