import React, { createContext, useState, useContext, useEffect } from 'react';
import { TUTOR_TOKEN, STUDENT_TOKEN, STAFF_TOKEN } from '../constants';
import tutorApi from '../dashboard/tutor/tutorApi';
import studentApi from '../dashboard/student/studentApi';
import staffApi from '../dashboard/staff/staffApi';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null); // 'student' or 'tutor' or 'staff'
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const tutorToken = localStorage.getItem(TUTOR_TOKEN);
      const studentToken = localStorage.getItem(STUDENT_TOKEN);
      const staffToken = localStorage.getItem(STAFF_TOKEN);

      if (studentToken) {
        try {
          const userData = await studentApi.getStudentInfo();
          setIsAuthenticated(true);
          setUser(userData);
          setUserRole('student');
        } catch (error) {
          console.error('Failed to fetch student data:', error);
          logout();
        }
      }else if (tutorToken) {
        try {
          const userData = await tutorApi.getTutorBasicInfo();
          setIsAuthenticated(true);
          setUser(userData);
          setUserRole('tutor');
        } catch (error) {
          console.error('Failed to fetch tutor data:', error);
          logout();
        }
      }else if (staffToken) {
        try {
          const userData = await staffApi.getStaffBasicInfo();
          setIsAuthenticated(true);
          setUser(userData);
          setUserRole('staff');
        } catch (error) {
          console.error('Failed to fetch staff data:', error);
          logout();
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const login = async (userData, token, role) => {
    console.log('Login called with:', { userData, token, role });
    
    // Set the appropriate token based on role
    switch(role) {
      case 'student':
        localStorage.setItem(STUDENT_TOKEN, token);
        break;
      case 'tutor':
        localStorage.setItem(TUTOR_TOKEN, token);
        break;
      case 'staff':
        console.log('setting staff token in localstorage')
        localStorage.setItem(STAFF_TOKEN, token);
        break;
      default:
        console.error('Invalid role specified');
        return;
    }
    
    // Update the state
    setIsAuthenticated(true);
    setUser(userData);
    setUserRole(role);
    
    console.log('Auth state updated:', { isAuthenticated: true, role });
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setUserRole(null);
    localStorage.removeItem(TUTOR_TOKEN);
    localStorage.removeItem(STUDENT_TOKEN);
    localStorage.removeItem(STAFF_TOKEN);
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      user, 
      userRole,
      login, 
      logout, 
      loading 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);