import React from 'react';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Inbox from './inbox/Inbox';
import Lessons from './Lessons';
import Profile from './Profile';
import Payments from './Payments';


function StudentHome({ activeComponent }) {
    console.log(activeComponent)

  const renderComponent = () => {
    console.log(activeComponent)
    switch (activeComponent) {
      case 'Inbox':
        return <Inbox />;
      case 'Lessons':
        return <Lessons />;
      case 'Profile':
        return <Profile />;
      case 'Payments':
        return <Payments/>
      default:
        return <Dashboard/>;
    }
  };

  return (
    <Sidebar activeComponent={activeComponent}>
      {renderComponent()}
    </Sidebar>
  );
}

export default StudentHome;