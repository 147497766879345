import React from 'react';
import styled from 'styled-components';
import RequirementDetails from './RequirementDetails';
import MeetingDetails from './MeetingDetails';
import LessonStatus from './LessonStatus';

function DetailsPanel({ selectedChat }) {
    return (
        <DetailsColumn>
            <ColumnHeader>Details</ColumnHeader>
            {selectedChat && (
                <>
                    <LessonStatus selectedChat={selectedChat} />
                    <DetailContent>
                        <RequirementDetails selectedChat={selectedChat} />
                        <MeetingDetails selectedChat={selectedChat} />
                    </DetailContent>
                </>
            )}
        </DetailsColumn>
    );
}

const DetailsColumn = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }
`;

const ColumnHeader = styled.div`
    padding: 20px;
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
`;

const DetailContent = styled.div`
    padding: 20px;
`;

export default DetailsPanel; 