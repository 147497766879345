import React, { useEffect } from 'react';
import styled from 'styled-components';
import { studentApi } from '../../studentApi';

function ChatMessages({ 
    selectedChat, 
    messages, 
    loadingMessages, 
    messageError, 
    newMessage, 
    sendingMessage,
    onMessageChange,
    onKeyDown,
    onSendMessage 
}) {
    useEffect(() => {
        if (selectedChat && messages.length > 0) {
            const hasUnreadMessages = messages.some(
                message => !message.isRead && message.user.id !== 'student'
            );
            
            if (hasUnreadMessages) {
                studentApi.markMessagesAsRead(selectedChat.id)
                    .catch(error => console.error('Error marking messages as read:', error));
            }
        }
    }, [selectedChat, messages]);

    return (
        <ChatColumn>
            {selectedChat ? (
                <>
                    <ColumnHeader>{selectedChat.name}</ColumnHeader>
                    <MessagesContainer>
                        <MessagesWrapper>
                            {loadingMessages ? (
                                <LoadingText>Loading messages...</LoadingText>
                            ) : messageError ? (
                                <ErrorText>{messageError}</ErrorText>
                            ) : messages.length === 0 ? (
                                <NoMessagesText>No messages yet</NoMessagesText>
                            ) : (
                                messages.map((message) => (
                                    <MessageWrapper 
                                        key={message.id}
                                        isOwn={message.user.id === 'student'}
                                    >
                                        <MessageContent isOwn={message.user.id === 'student'}>
                                            <MessageText>{message.text}</MessageText>
                                            <MessageFooter>
                                                <MessageTime>
                                                    {new Date(message.createdAt).toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                </MessageTime>
                                                {message.user.id === 'student' && (
                                                    <ReadStatus isRead={message.isRead}>
                                                        {message.isRead ? (
                                                            <>
                                                                Read
                                                                <ReadTime>
                                                                    {message.readAt && new Date(message.readAt).toLocaleTimeString([], {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit'
                                                                    })}
                                                                </ReadTime>
                                                            </>
                                                        ) : (
                                                            'Delivered'
                                                        )}
                                                    </ReadStatus>
                                                )}
                                            </MessageFooter>
                                        </MessageContent>
                                    </MessageWrapper>
                                ))
                            )}
                        </MessagesWrapper>
                    </MessagesContainer>
                    <MessageInputContainer>
                        <MessageForm onSubmit={onSendMessage}>
                            <MessageInput
                                value={newMessage}
                                onChange={onMessageChange}
                                onKeyDown={onKeyDown}
                                placeholder="Type a message... (Shift + Enter for new line)"
                                disabled={sendingMessage}
                                rows={1}
                            />
                            <SendButton 
                                type="submit"
                                disabled={sendingMessage || !newMessage.trim()}
                            >
                                {sendingMessage ? 'Sending...' : 'Send'}
                            </SendButton>
                        </MessageForm>
                    </MessageInputContainer>
                </>
            ) : (
                <NoChat>Select a conversation to start chatting</NoChat>
            )}
        </ChatColumn>
    );
}

const ChatColumn = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 90vh;
    position: relative;
`;

const ColumnHeader = styled.div`
    padding: 20px;
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
`;

const MessagesContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    margin-bottom: 80px;
    
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }
`;

const MessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const MessageWrapper = styled.div`
    display: flex;
    justify-content: ${props => props.isOwn ? 'flex-end' : 'flex-start'};
    margin: 5px 0;
`;

const MessageContent = styled.div`
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 15px;
    background-color: ${props => props.isOwn ? '#007bff' : '#e9ecef'};
    color: ${props => props.isOwn ? 'white' : 'black'};
`;

const MessageText = styled.div`
    white-space: pre-wrap;
    word-break: break-word;
`;

const MessageFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-top: 5px;
`;

const MessageTime = styled.div`
    font-size: 0.75rem;
    opacity: 0.7;
`;

const ReadStatus = styled.span`
    font-size: 0.75rem;
    opacity: 0.7;
    color: ${props => props.isRead ? '#34b7f1' : '#a8a8a8'};
    display: flex;
    align-items: center;
    gap: 4px;
`;

const ReadTime = styled.span`
    font-size: 0.7rem;
    opacity: 0.8;
`;

const MessageInputContainer = styled.div`
    padding: 20px;
    border-top: 1px solid #e0e0e0;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;

const MessageForm = styled.form`
    display: flex;
    gap: 10px;
    align-items: flex-end;
    max-width: 100%;
`;

const MessageInput = styled.textarea`
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    outline: none;
    font-size: 1rem;
    resize: none;
    min-height: 40px;
    max-height: 120px;
    width: 100%;
    opacity: ${props => props.disabled ? 0.7 : 1};
    font-family: inherit;

    &:focus {
        border-color: #007bff;
    }
`;

const SendButton = styled.button`
    padding: 10px 20px;
    background-color: ${props => props.disabled ? '#ccc' : '#007bff'};
    color: white;
    border: none;
    border-radius: 20px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    font-weight: bold;

    &:hover {
        background-color: ${props => props.disabled ? '#ccc' : '#0056b3'};
    }
`;

const NoChat = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #666;
`;

const LoadingText = styled.div`
    padding: 20px;
    text-align: center;
    color: #666;
`;

const ErrorText = styled.div`
    padding: 20px;
    text-align: center;
    color: #dc3545;
`;

const NoMessagesText = styled.div`
    text-align: center;
    padding: 20px;
    color: #666;
`;

export default ChatMessages; 