import React, { useState, useEffect } from 'react';
import { getActiveClasses, getInvoices} from './studentApi';

const Lessons = () => {
  const [activeClasses, setActiveClasses] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [classes, setClasses] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [classesData, invoicesData] = await Promise.all([
        getActiveClasses(),
        getInvoices()
      ]);
      //console.log(invoicesData);
      console.log(classesData);
      setActiveClasses(classesData);
      setInvoices(invoicesData);
    } catch (err) {
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  if (loading) {
    return <div className="container mx-auto p-4 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Class Management</h1>

      {/* Active Classes Table */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Active Classes</h2>
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b">Tutor Name</th>
              <th className="py-2 px-4 border-b">Subject</th>
              <th className="py-2 px-4 border-b">Hours Completed</th>
              <th className="py-2 px-4 border-b">Total Hours</th>
              <th className="py-2 px-4 border-b">Meeting Link</th>
            </tr>
          </thead>
          <tbody>
            {activeClasses.map((classItem) => (
              <tr key={classItem.id}>
                <td className="py-2 px-4">{classItem.studentName}</td>
                <td className="py-2 px-4">{classItem.subject}</td>
                <td className="py-2 px-4">{classItem.totalHours - classItem.remainingHours}</td>
                <td className="py-2 px-4">{classItem.totalHours}</td>
                <td className="py-2 px-4">
                  <a 
                    href={classItem.meetingLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Join Class
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Invoices Table */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Sessions</h2>
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Subject</th>
              <th className="py-2 px-4 border-b">Duration (Hours)</th>
              <th className="py-2 px-4 border-b">Notes</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{new Date(invoice.date).toLocaleDateString()}</td>
                <td className="py-2 px-4">{invoice.subject}</td>
                <td className="py-2 px-4">{invoice.duration}</td>
                <td className="py-2 px-4">{invoice.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Lessons;
